import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap/';
import { Card, CardBody } from '@Comp';
import { View } from '../../shared/components';
import CompanyDetails from './company_details/index';
import BusinessFinancialState from './business-financials-state';
import CashFlowRecords from './cashflow-records';
import PastInvoices from './past_invoices';
import { inject, observer } from 'mobx-react';
import ProgressBar from './ProgressBar';
import DataVerificationLink from './data_verification_links';
import { OauthIntegrations } from '../../services/requests';
//import MasterPdfView from './pdf/index';

@inject('commonStore', 'businessProfileStore')
@observer
class BusinessProfileCopy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyTab: 'company_details',
            msg: null,
            isLoaded: false,
            providerAuthData: null,
            hasCode: false
        };
    }

    async componentDidMount() {
        const { businessProfileStore, id, isViewable, commonStore } = this.props;
        const savedTab = localStorage.getItem("selectedTab"); 
        if(savedTab) { 
            this.setState({keyTab : savedTab})
        }
        if (this.props.match && this.props.location) {
            const { provider } = this.props.match.params;
            const queryParams = new URLSearchParams(this.props.location.search);
            const { randomString } = businessProfileStore;
            if (
                queryParams.has('code') &&
                queryParams.has('state') &&
                queryParams.get('state') === randomString
            ) {
                this.setState({ keyTab: 'data_verification_links' });
                await OauthIntegrations.setAuthData({
                    code: queryParams.get('code'),
                    provider
                });
            }
        }

        try {
            if (!commonStore.getPublicProfile) {
                const { data } = await OauthIntegrations.getAuthData();
                this.setState({ providerAuthData: data });
            }
        } catch (err) {
            console.log(err); //NOSONAR
        }
        if (id === undefined) {
            businessProfileStore.getUpdatedData().then(() => {
                this.setState({ isLoaded: true });
            });
        } else {
            await businessProfileStore.getUpdatedData(id);
            if (this.props.commonStore.isProfileViewError) {
                return false;
            }
            if (isViewable) { // executed when public profile called
                await businessProfileStore.isProfileVerified();
                this.props.businessProfileStore.businessProfile.isEditable = false;
            }
            this.setState({ isLoaded: true });
        }
        return true;
    }

    updateProviderData = async () => {
        const { data } = await OauthIntegrations.getAuthData();
        this.setState({ providerAuthData: data });
    }
    setTab = (keyTab) => {
        this.setState({ keyTab });
        localStorage.setItem("selectedTab", keyTab)

        const {tourStep} = this.props.commonStore;
        if(keyTab==='data_verification_links' && tourStep < 10){
            this.props.commonStore.tourStep = 10;
        }
    }
    render() {
        const complete = true;
        const { isViewable, businessProfileStore } = this.props;
        return (
            <View>
                <ProgressBar
                    progressBar={this.props.businessProfileStore.profilePercenatge}
                    isEditable={this.props.businessProfileStore.businessProfile.isEditable}
                    isViewable={isViewable}
                    businessProfile={businessProfileStore && businessProfileStore.businessProfile}
                />
                <div className="view-content view-components">
                    <Card className="mb-4 sixth-step">
                        <CardBody>
                            <Tab.Container
                                id="controlled-tab-example"
                                activeKey={this.state.keyTab}
                                onSelect={keyTab => this.setTab(keyTab)}
                            >
                                <Nav variant="tabs">
                                    <Nav.Item className="">
                                        <Nav.Link eventKey="company_details">
                                            Company Details
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="business_financial_state">
                                            Business Financial State
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="cashflows">Cashflow Records</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="past_invoices">Past Invoices</Nav.Link>
                                    </Nav.Item>
                                    {complete && !isViewable &&(
                                        <Nav.Item className="ninth-step">
                                            <Nav.Link eventKey="data_verification_links">
                                                Data Verification Links
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}
                                    {/*<Nav.Item>
                                        <Nav.Link eventKey="pdf-view">Pdf Viewier</Nav.Link>
                                    </Nav.Item>*/}
                                </Nav>
                                {this.state.isLoaded && (
                                    <Tab.Content>
                                        <Tab.Pane eventKey="company_details">
                                            <CompanyDetails isViewable={isViewable} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="business_financial_state">
                                            {this.state.keyTab === 'business_financial_state' && (
                                                <BusinessFinancialState />
                                            )}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="cashflows">
                                            {this.state.keyTab === 'cashflows' && <CashFlowRecords />}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="past_invoices">
                                            {this.state.keyTab === 'past_invoices' && (
                                                <PastInvoices />
                                            )}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="data_verification_links">
                                            {this.state.keyTab === 'data_verification_links' && (
                                                <DataVerificationLink
                                                    providerAuthData={this.state.providerAuthData}
                                                    updateProviderData={this.updateProviderData}
                                                />
                                            )}
                                        </Tab.Pane>
                                        {/*<Tab.Pane eventKey="pdf-view">
                                            {this.state.keyTab === 'pdf-view' && (
                                                <MasterPdfView />
                                            )}
                                            </Tab.Pane>*/}
                                    </Tab.Content>
                                )}
                            </Tab.Container>
                        </CardBody>
                    </Card>
                </div>
            </View>
        );
    }
}

export default BusinessProfileCopy;
