let  BASE_URL = 'https://dev-api.nisafinance.com';
let  FrontEndURL = 'https://app.nisafinance.com';
let NISADOCLOCK_EXTENSION_ID = 'ggppajielldmegaafnodbplnjiefoohb';
if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    BASE_URL = 'https://dev-api.nisafinance.com';
    BASE_URL = 'https://dev-api.nisafinance.com';
    FrontEndURL = 'https://app.nisafinance.com';
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    BASE_URL = 'https://dev-api.nisafinance.com';
    FrontEndURL = 'https://app.nisafinance.com';
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    BASE_URL = 'https://dev-api.nisafinance.com';
    BASE_URL = 'https://dev-api.nisafinance.com';
    FrontEndURL = 'https://app.nisafinance.com';
} else{
    BASE_URL = 'https://dev-api.nisafinance.com';
    BASE_URL = 'https://dev-api.nisafinance.com';
    FrontEndURL = 'https://app.nisafinance.com';
}

const config = {
    BASE_URL,
    FrontEndURL,
    NISADOCLOCK_EXTENSION_ID,
    devMode: false,
    // Use Mock Services
    devModeServiceDelay: 3000,
     // How long the service should take
    BASE_URL_PREFIX: '',
    BASE_URL_AUTH: '/auth',
    FORGOT_PASSWORD_URL: '/change-password'};

export default config;
